
import { defineComponent, ref, onMounted } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  modalKeyDownListener,
  modalShowListener,
  showModal,
} from "@/core/directive/function/common";
import _ from "lodash";
import ElInput from "element-plus/es/components/input";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  SweetAlertOptions,
  SweetAlertIcon,
} from "sweetalert2/dist/sweetalert2.js";
import { ApiInbound } from "@/core/api";
import { InboundStatus } from "@/core/directive/type/inbound";
import { getUserWarhouseName } from "@/core/directive/function/user";

export default defineComponent({
  name: "inbound-receiving-package-modal",
  components: {},
  emits: ["update-list", "reset-form", "show-edit"],
  props: {
    submitType: {
      type: Number,
      default: 1,
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const InboundReceivingPackageModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const barcodeRef = ref<InstanceType<typeof ElInput>>();

    const { showValidateErrorMsg, showServerErrorMsg } = mixin();

    const formData = ref({
      barcode: "",
    });

    const options = ref({});

    const rules = ref({
      barcode: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const reject = async (id) => {
      loading.value = true;
      const { data } =
        await ApiInbound.updateInboundWarehouseStockInBatchStatus({
          id: id,
        });
      loading.value = false;
      if (data.code === 0) {
        updateList();
      }
    };

    const showSwal = (data) => {
      let html = "",
        icon = "success",
        title = t("inbound.scanSuccessTitle");
      if (
        data.status === InboundStatus.Incoming ||
        data.status === InboundStatus.Matched ||
        data.status === InboundStatus.Received
      ) {
        html = `<div class='text-start'>
          <div class="text-gray-400 text-center mb-6">${t(
            "inbound.scanSuccessSubTitle"
          )}</div>
          <div class="d-flex align-items-center py-2">
            <div class="text-gray-400 flex-1">${t(
              "inbound.logisticsServiceProvider"
            )}</div>
            <div class="fw-bold flex-1">${data.service_provider}</div>
          </div>
          <div class="d-flex align-items-center py-2">
            <div class="text-gray-400 flex-1">${t("shipments.trackingNo")}</div>
            <div class="fw-bold flex-1">${data.tracking_no}</div>
          </div>
          <div class="d-flex align-items-center py-2">
            <div class="text-gray-400 flex-1">${t("inbound.consigner")}</div>
            <div class="fw-bold flex-1">${data.consigner}</div>
          </div>
          <div class="d-flex align-items-center py-2">
            <div class="text-gray-400 flex-1">${t("inbound.shippingDate")}</div>
            <div class="fw-bold flex-1">${formatDate(data.shipping_date)}</div>
          </div>
          <div class="notice d-flex bg-light rounded border-secondary border border-dashed p-6 mt-14"
          >
            <img src="media/svg/custom/scan_code.svg" class="w-60px me-6" />
            <div class="d-flex flex-stack flex-grow-1">
              <div class="fw-semibold">
                <div class="fs-6 text-gray-700 text-line-clamp-2">${t(
                  "inbound.scanNotice"
                )}</div>
              </div>
            </div>
          </div>
        </div>`;
      } else {
        icon = "warning";
        title = t("inbound.scanWarningTitle");
        html = `<div class='text-start'>
          <div class="text-gray-400 text-center mb-6">${t(
            "inbound.scanWarningSubTitle"
          )}</div>
          <div class="d-flex align-items-center py-2">
            <div class="flex-1 text-gray-400">${t("shipments.trackingNo")}</div>
            <div class="flex-1 fw-bold">${data.tracking_no}</div>
          </div>
          <div class="notice d-flex bg-light rounded border-secondary border border-dashed p-6 mt-14"
          >
            <img src="media/svg/custom/scan_code.svg" class="w-60px me-6" />
            <div class="d-flex flex-stack flex-grow-1">
              <div class="fw-semibold">
                <div class="fs-6 text-gray-700 text-line-clamp-2">${t(
                  "inbound.scanNotice"
                )}</div>
              </div>
            </div>
          </div>
        </div>`;
      }

      Swal.fire({
        title: title as string,
        content: "",
        html: html,
        icon: icon as SweetAlertIcon,
        showCancelButton: false,
        // showConfirmButton: false,
        buttonsStyling: false,
        confirmButtonText: t("common.edit"),
        cancelButtonText: t("inbound.reject"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
          closeButton: "btn btn-icon btn-sm btn-active-icon-primary",
          actions: "d-flex justify-content-end",
          container: "scan-code-container",
          content: "scan-code-content",
        },
        // allowOutsideClick: false,
        showCloseButton: true,
        closeButtonHtml: `<span class="svg-icon svg-icon-1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"/>
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"/>
              </svg>
            </span>`,
        width: 650,
      } as SweetAlertOptions).then((result) => {
        if (result.isConfirmed) {
          updateList();
          emit("show-edit", data.id);
        } else {
          // reject(data.id);
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          if (props.submitType === 1) {
            var re = /^[0-9a-zA-Z]*$/g;
            if (re.test(formData.value.barcode)) {
              inboundSubmit();
            } else {
              const html = `<div>
                <div class='fs-4 fw-bold'>${formData.value.barcode}</div>  
                <div class='fs-5'>${t("inbound.scanError")}</div> 
              </div>`;
              Swal.fire({
                html: html,
                icon: "warning",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: t("common.okButContinue"),
                cancelButtonText: t("common.yesClose"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-danger",
                },
                allowOutsideClick: false,
                width: 415,
              }).then((result) => {
                if (result.isConfirmed) {
                  inboundSubmit();
                } else {
                  modalHide();
                }
              });
            }
          } else {
            inspectionSubmit();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const inboundSubmit = async (checked = 0) => {
      loading.value = true;
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      const { data } = await ApiInbound.stockBatchScanCode({
        code: formData.value.barcode,
        checked: checked,
      });
      loading.value = false;
      submitButton.value?.removeAttribute("data-kt-indicator");
      if (data.code === 0) {
        showSwal(data.data);
        emit("update-list");
      } else if (data.code === 10000 && data.sub_code === "50000") {
        Swal.fire({
          text: t("inbound.receivedDifWahouseTip"),
          icon: "warning",
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: t("common.okConfirm"),
          cancelButtonText: t("common.nopeCancelIt"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light",
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            inboundSubmit(1);
          }
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const inspectionSubmit = () => {
      const number = formData.value.barcode;
      emit("update-list", number);
      // modalHide();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const updateList = () => {
      emit("update-list");
      modalHide();
    };

    const modalShow = () => {
      showModal(InboundReceivingPackageModalRef.value);
    };

    const modalHide = () => {
      hideModal(InboundReceivingPackageModalRef.value);
    };

    const restart = () => {
      resetForm();
      init();
    };

    const init = () => {
      setTimeout(() => {
        barcodeRef.value?.focus();
      }, 500);
    };

    onMounted(() => {
      modalShowListener(InboundReceivingPackageModalRef.value, () => {
        modalKeyDownListener(InboundReceivingPackageModalRef.value, (e) => {
          if (e.key == "Enter") {
            submit();
          }
        });
        init();
      });
      modalHideListener(InboundReceivingPackageModalRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      getUserWarhouseName,
      props,
      loading,
      formData,
      rules,
      formRef,
      barcodeRef,
      InboundReceivingPackageModalRef,
      submitButton,
      options,
      modalShow,
      modalHide,
      restart,
      submit,
      resetForm,
      updateList,
    };
  },
});
